import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import Header from "./Header";
import HeroList, { HeroListItem } from "./HeroList";
import Progress from "./Progress";
import UUVORangiranjeOcjenaRangova from "./UUVORangiranjeOcjenaRangova";
import TezineKriterija from "./TezineKriterija";
import UsporedivanjeUParovima from "./UsporedivanjeUParovima";
import Elektra from "./Elektra";
import { Divider } from "@mantine/core";
import Upute from "./Upute";
import Rangiranje from "./Rangiranje";

/* global console, Excel, require  */

export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
}

export interface AppState {
    listItems: HeroListItem[];
}

export default class App extends React.Component<AppProps, AppState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            listItems: [],
        };
    }

    componentDidMount() {
        this.setState({
            listItems: [
                {
                    icon: "Ribbon",
                    primaryText: "Achieve more with Office integration",
                },
                {
                    icon: "Unlock",
                    primaryText: "Unlock features and functionality",
                },
                {
                    icon: "Design",
                    primaryText: "Create and visualize like a pro",
                },
            ],
        });
    }

    click = async () => {
        try {
            await Excel.run(async (context) => {
                /**
                 * Insert your Excel code here
                 */
                context.workbook.getSelectedRange();
                const range = context.workbook.getSelectedRange();

                // Read the range address
                range.load("address");

                // Update the fill color
                range.format.fill.color = "yellow";

                await context.sync();
                console.log(`The range address was ${range.address}.`);
            });
        } catch (error) {
            console.error(error);
        }
    };

    render() {
        const { title, isOfficeInitialized } = this.props;

        if (!isOfficeInitialized) {
            return (
                <Progress
                    title={title}
                    logo={require("./../../../assets/logo-filled.png")}
                    message="Please sideload your addin to see app body."
                />
            );
        }

        return (
            <div className="ms-welcome">
                <Rangiranje />
                <Divider my="sm"/>
                <TezineKriterija />
                <Divider my="sm"/>
                <UsporedivanjeUParovima />
                <Divider my="sm"/>
                <Elektra />
                <Divider my="sm"/>
                <Upute />
            </div>
        );
    }
}

import { Button, Center, Checkbox, Container, Flex, Radio, TextInput, Title } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import {
    Address,
    COPYTO_CELL,
    LOAD_ADDRESS,
    RangeToAddress,
    rangeToAddress,
    registerOnSelectionChangeEventHandler,
    removeOnSelectionChangedEventHandler,
    selectionChange,
} from "../utils/utils";

export enum ElektraNormalizacija {
    Euklidska = "e",
    Postona = "p",
    Zbrojem = "z",
    NulJen = "n",
}

type InputTableValues = "tOdlucivanja" | "tNorm" | "tPonNorm";

export default function Elektra() {
    const [selection, _setSelection] = useState<Excel.Range>(undefined);
    const [inputTable, setInputTable] = useState<InputTableValues>("tOdlucivanja");
    const [normalizacija, setNormalizacija] = useState<ElektraNormalizacija>(ElektraNormalizacija.Euklidska);
    const [onlyNorm, setOnlyNorm] = useState<boolean>(false);

    const setSelection = useCallback((range: Excel.Range) => {
        _setSelection((old) => {
            (async () => {
                old.context.trackedObjects.remove(old);
                await old.context.sync();
                console.log("Removing old tracked object");
            })();
            range.context.trackedObjects.add(range);
            return range;
        });
    }, []);

    const selectionChangeHandler = useCallback(async (change: Excel.SelectionChangedEventArgs) => {
        setSelection(await selectionChange(change));
    }, []);

    useEffect(() => {
        const result = registerOnSelectionChangeEventHandler(selectionChangeHandler);
        return () => {
            removeOnSelectionChangedEventHandler(result);
        };
    }, [selectionChange]);
    return (
        <Container>
            <Flex direction="column" gap="sm">
                <Center>
                    <Title order={4}>Elektra</Title>
                </Center>
                <TextInput label={"Odabrane ćelije"} value={selection ? selection.address : ""} disabled />
                <Radio.Group
                    label={"Ulazna tablica"}
                    value={inputTable}
                    onChange={(val: InputTableValues) => {
                        setInputTable(val);
                    }}
                >
                    <Radio value="tOdlucivanja" label={"Tablica odlučivanja"} disabled={onlyNorm}/>
                    <Radio value="tNorm" label={"Normalizirana matrica odlučivanja"} disabled={onlyNorm} />
                    <Radio
                        value="tPonNorm"
                        label={"Ponderirana normalizirana matrica odlučivanja"}
                        disabled={onlyNorm}
                    />
                </Radio.Group>
                <Radio.Group
                    label={"Normalizacija"}
                    value={normalizacija}
                    onChange={(val: ElektraNormalizacija) => {
                        setNormalizacija(val);
                    }}
                >
                    <Radio
                        disabled={inputTable !== "tOdlucivanja" && !onlyNorm}
                        value={ElektraNormalizacija.Euklidska}
                        label={"Euklidska"}
                    />
                    <Radio
                        disabled={inputTable !== "tOdlucivanja" && !onlyNorm}
                        value={ElektraNormalizacija.Postona}
                        label={"Postotna"}
                    />
                    <Radio
                        disabled={inputTable !== "tOdlucivanja" && !onlyNorm}
                        value={ElektraNormalizacija.Zbrojem}
                        label={"Zbrojem"}
                    />
                    <Radio disabled={inputTable !== "tOdlucivanja" && !onlyNorm} value={ElektraNormalizacija.NulJen} label={"0-1"} />
                </Radio.Group>
                <Checkbox
                    label={"Samo normalizacija (za topsis)"}
                    onChange={() => {
                        setOnlyNorm((old) => !old);
                    }}
                />
                <Button
                    color="gray"
                    onClick={() => {
                        Excel.run(selection.context, async (context) => {
                            const newSheet = context.workbook.worksheets.add();
                            newSheet.activate();
                            newSheet.getRange(COPYTO_CELL).copyFrom(selection, undefined, undefined, false);

                            const initialTableColumnCount = selection.columnCount;
                            const initialTableRowCount = selection.rowCount;

                            const kriterijiCount = initialTableColumnCount - 1;
                            const alternativeCount = initialTableRowCount - 3;

                            const mainTable = newSheet
                                .getRange(COPYTO_CELL)
                                .getResizedRange(initialTableRowCount - 1, initialTableColumnCount - 1);
                            const tezineRange = mainTable.getRow(0).getResizedRange(0, -1).getOffsetRange(0, 1);
                            const minMaxRange = mainTable.getRow(0).getResizedRange(0, -1).getOffsetRange(1, 1);
                            const kriterijiRange = minMaxRange.getOffsetRange(1, 0);
                            const mainTableValuesRange = kriterijiRange.getRowsBelow(alternativeCount);
                            const alternativeRange = mainTableValuesRange.getColumnsBefore(1);

                            const mainTableValuesStartCell = mainTableValuesRange.getCell(0, 0).load(LOAD_ADDRESS);
                            await context.sync();
                            const mainTableStartCellAddress = new Address(mainTableValuesStartCell.address);

                            // WARN: Normalizacija

                            let normTableRange: Excel.Range;
                            let normTableValuesRange: Excel.Range;
                            let normTableValuesAddress: RangeToAddress;

                            if (inputTable === "tOdlucivanja" || onlyNorm) {
                                const normConRange = mainTableValuesRange
                                    .getRowsBelow(1)
                                    .getRowsBelow(1)
                                    .getResizedRange(0, 1)
                                    .getOffsetRange(2, -1);

                                normConRange.getRowsAbove(2).getCell(0, 0).values = [
                                    ["2) Normalizirana matrica odlučivanja"],
                                ];
                                for (let col = 0; col < kriterijiCount; col++) {
                                    switch (normalizacija) {
                                        case ElektraNormalizacija.Euklidska: {
                                            normConRange.getColumn(0).values = [["Euklidska norm"]];
                                            let partialFormula = "";
                                            for (let row = 0; row < alternativeCount; row++) {
                                                const addr = mainTableStartCellAddress.add({
                                                    columnCount: col,
                                                    rowCount: row,
                                                });
                                                partialFormula = partialFormula.concat(
                                                    `${row === 0 ? "" : "+"}${addr}*${addr}`
                                                );
                                            }
                                            normConRange.getColumn(col + 1).values = [[`=SQRT(${partialFormula})`]];
                                            break;
                                        }
                                        case ElektraNormalizacija.Postona: {
                                            normConRange.getColumn(0).values = [["Postotna norm"]];
                                            normConRange.getColumn(col + 1).values = [
                                                [
                                                    `=MAX(${mainTableStartCellAddress.add({
                                                        columnCount: col,
                                                    })}:${mainTableStartCellAddress.add({
                                                        columnCount: col,
                                                        rowCount: alternativeCount - 1,
                                                    })})`,
                                                ],
                                            ];
                                            break;
                                        }
                                        case ElektraNormalizacija.Zbrojem: {
                                            normConRange.getColumn(0).values = [["Norm. Zbrojem"]];
                                            normConRange.getColumn(col + 1).values = [
                                                [
                                                    `=SUM(${mainTableStartCellAddress.add({
                                                        columnCount: col,
                                                    })}:${mainTableStartCellAddress.add({
                                                        columnCount: col,
                                                        rowCount: alternativeCount - 1,
                                                    })})`,
                                                ],
                                            ];
                                            break;
                                        }
                                        case ElektraNormalizacija.NulJen: {
                                            normConRange.getColumn(0).values = [["0-1 norm"]];
                                            break;
                                        }
                                    }
                                }

                                normConRange.getRowsBelow(1).getOffsetRange(1, 0).copyFrom(mainTable);

                                normTableRange = normConRange
                                    .getRowsBelow(1)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(initialTableRowCount - 1, 0);

                                normTableValuesRange = normTableRange.getResizedRange(-3, -1).getOffsetRange(3, 1);
                                normTableValuesRange.load(LOAD_ADDRESS);
                                normConRange.load(LOAD_ADDRESS);
                                await context.sync();
                                const normConRangeAddress = rangeToAddress(normConRange.address);
                                normTableValuesAddress = rangeToAddress(normTableValuesRange.address);

                                if (normalizacija !== ElektraNormalizacija.NulJen) {
                                    for (let col = 0; col < kriterijiCount; col++) {
                                        const devideBy = new Address(
                                            normConRangeAddress.from.add({ columnCount: 1 + col })
                                        );
                                        devideBy.freezeRow();

                                        for (let row = 0; row < alternativeCount; row++) {
                                            const addr = mainTableStartCellAddress.add({
                                                columnCount: col,
                                                rowCount: row,
                                            });
                                            normTableValuesRange.getCell(row, col).values = [
                                                [`=${addr}/${devideBy.getValue()}`],
                                            ];
                                        }
                                    }
                                } else {
                                    for (let col = 0; col < kriterijiCount; col++) {
                                        const rangeStart = new Address(
                                            mainTableStartCellAddress.add({ columnCount: col })
                                        );
                                        const rangeEnd = new Address(
                                            mainTableStartCellAddress.add({
                                                columnCount: col,
                                                rowCount: alternativeCount - 1,
                                            })
                                        );
                                        rangeStart.freezeRow();
                                        rangeEnd.freezeRow();
                                        const rangeString = `${rangeStart.getValue()}:${rangeEnd.getValue()}`;
                                        for (let row = 0; row < alternativeCount; row++) {
                                            const cell = mainTableStartCellAddress.add({
                                                columnCount: col,
                                                rowCount: row,
                                            });
                                            normTableValuesRange.getCell(row, col).values = [
                                                [
                                                    `=1-(MAX(${rangeString})-${cell})/(MAX(${rangeString})-MIN(${rangeString}))`,
                                                ],
                                            ];
                                        }
                                    }
                                }
                            } else {
                                normTableRange = mainTable;
                                normTableValuesRange = mainTableValuesRange;
                                mainTableValuesRange.load(LOAD_ADDRESS);
                                await context.sync();
                                normTableValuesAddress = rangeToAddress(normTableValuesRange.address);
                            }

                            // WARN: Ponderirana normalizirana matrica

                            let ponderNormMatRange: Excel.Range;
                            let ponderNormMatValuesAddr: RangeToAddress;

                            if (inputTable === "tOdlucivanja" || inputTable === "tNorm" || onlyNorm) {
                                normTableRange.getRowsBelow(1).getOffsetRange(3, 0).copyFrom(mainTable);
                                ponderNormMatRange = normTableRange
                                    .getRowsBelow(1)
                                    .getOffsetRange(3, 0)
                                    .getResizedRange(initialTableRowCount - 1, 0);

                                ponderNormMatRange.getRowsAbove(2).getCell(0, 0).values = [
                                    ["3) Ponderirana normalizirana matrica odlučivanja"],
                                ];

                                const ponderNormMatValuesRange = ponderNormMatRange
                                    .getResizedRange(-3, -1)
                                    .getOffsetRange(3, 1);
                                const ponderNormMatWeightsRange = ponderNormMatValuesRange
                                    .getRowsAbove(1)
                                    .getOffsetRange(-2, 0);

                                normTableValuesRange.load(LOAD_ADDRESS);
                                ponderNormMatWeightsRange.load(LOAD_ADDRESS);
                                ponderNormMatValuesRange.load(LOAD_ADDRESS);
                                await context.sync();

                                const ponderNormMatWeightAddress = rangeToAddress(ponderNormMatWeightsRange.address);
                                ponderNormMatValuesAddr = rangeToAddress(ponderNormMatValuesRange.address);

                                for (let col = 0; col < kriterijiCount; col++) {
                                    const multiply = new Address(
                                        ponderNormMatWeightAddress.from.add({ columnCount: col })
                                    );
                                    multiply.freezeRow();

                                    for (let row = 0; row < alternativeCount; row++) {
                                        const addr = normTableValuesAddress.from.add({
                                            columnCount: col,
                                            rowCount: row,
                                        });
                                        ponderNormMatValuesRange.getCell(row, col).values = [
                                            [`=${addr}*${multiply.getValue()}`],
                                        ];
                                    }
                                }
                            } else {
                                ponderNormMatRange = mainTable;
                                mainTableValuesRange.load(LOAD_ADDRESS);
                                await context.sync();
                                ponderNormMatValuesAddr = rangeToAddress(normTableValuesRange.address);
                            }

                            if (!onlyNorm) {
                                // WARN: Skupovi suglasnosti i nesuglasnosti

                                const skupSuglasnosti = ponderNormMatRange
                                    .getRowsBelow(1)
                                    .getOffsetRange(3, 0)
                                    .getResizedRange(1 + alternativeCount * (alternativeCount - 1), 0);

                                const skupSuglasnostiValuesRange = skupSuglasnosti
                                    .getResizedRange(-2, -1)
                                    .getOffsetRange(2, 1);
                                const skupSuglasnostiNamesRange = skupSuglasnostiValuesRange.getColumnsBefore(1);

                                skupSuglasnosti.getRowsAbove(2).getCell(0, 0).values = [
                                    ["4) Skupovi suglasnosti i nesuglasnosti"],
                                ];

                                skupSuglasnosti.getRow(0).getColumn(1).copyFrom(kriterijiRange);
                                skupSuglasnosti
                                    .getRow(1)
                                    .copyFrom(tezineRange.getResizedRange(0, 1).getOffsetRange(0, -1));

                                for (let col = 0; col < kriterijiCount; col++) {
                                    let mainRelativeRow: number = 0;
                                    let otherRelativeRow: number = 1;
                                    for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                        if (row % (alternativeCount - 1) === 0) {
                                            mainRelativeRow += 1;
                                            otherRelativeRow = 1;
                                        }

                                        if (mainRelativeRow === otherRelativeRow) {
                                            otherRelativeRow += 1;
                                        }

                                        if (col === 0) {
                                            skupSuglasnostiNamesRange.getRow(row).values = [
                                                [`C${mainRelativeRow}${otherRelativeRow}`],
                                            ];
                                        }

                                        skupSuglasnostiValuesRange.getColumn(col).getRow(row).values = [
                                            [
                                                `=IF(${ponderNormMatValuesAddr.from.add({
                                                    rowCount: mainRelativeRow - 1,
                                                    columnCount: col,
                                                })}>=${ponderNormMatValuesAddr.from.add({
                                                    rowCount: otherRelativeRow - 1,
                                                    columnCount: col,
                                                })}, "X", "")`,
                                            ],
                                        ];
                                        otherRelativeRow += 1;
                                    }
                                }

                                const skupNesuglasnosti = skupSuglasnosti
                                    .getColumnsAfter(1)
                                    .getOffsetRange(0, 2)
                                    .getResizedRange(0, kriterijiCount);

                                const skupNesuglasnostiValuesRange = skupNesuglasnosti
                                    .getResizedRange(-2, -1)
                                    .getOffsetRange(2, 1);
                                const skupNesuglasnostiNamesRange = skupNesuglasnostiValuesRange.getColumnsBefore(1);

                                skupNesuglasnosti.getRow(0).getColumn(1).copyFrom(kriterijiRange);
                                skupNesuglasnosti
                                    .getRow(1)
                                    .copyFrom(tezineRange.getResizedRange(0, 1).getOffsetRange(0, -1));

                                for (let col = 0; col < kriterijiCount; col++) {
                                    let mainRelativeRow: number = 0;
                                    let otherRelativeRow: number = 1;
                                    for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                        if (row % (alternativeCount - 1) === 0) {
                                            mainRelativeRow += 1;
                                            otherRelativeRow = 1;
                                        }

                                        if (mainRelativeRow === otherRelativeRow) {
                                            otherRelativeRow += 1;
                                        }

                                        if (col === 0) {
                                            skupNesuglasnostiNamesRange.getRow(row).values = [
                                                [`D${mainRelativeRow}${otherRelativeRow}`],
                                            ];
                                        }

                                        skupNesuglasnostiValuesRange.getColumn(col).getRow(row).values = [
                                            [
                                                `=IF(${ponderNormMatValuesAddr.from.add({
                                                    rowCount: mainRelativeRow - 1,
                                                    columnCount: col,
                                                })}<${ponderNormMatValuesAddr.from.add({
                                                    rowCount: otherRelativeRow - 1,
                                                    columnCount: col,
                                                })}, "X", "")`,
                                            ],
                                        ];
                                        otherRelativeRow += 1;
                                    }
                                }

                                // WARN: Matrica suglasnosti

                                const matricaSuglasnosti = skupSuglasnosti
                                    .getRowsBelow(1)
                                    .getOffsetRange(3, 0)
                                    .getResizedRange(1 + alternativeCount * (alternativeCount - 1), 0);

                                matricaSuglasnosti.copyFrom(skupSuglasnosti);

                                const matricaSuglasnostiValuesRange = matricaSuglasnosti
                                    .getResizedRange(-2, -1)
                                    .getOffsetRange(2, 1);

                                matricaSuglasnosti.getRowsAbove(2).getCell(0, 0).values = [["5) Matrica suglasnosti"]];

                                matricaSuglasnosti.getRow(0).getColumn(1).copyFrom(kriterijiRange);
                                matricaSuglasnosti
                                    .getRow(1)
                                    .copyFrom(tezineRange.getResizedRange(0, 1).getOffsetRange(0, -1));

                                const matricaSuglasnostiTezineRange = matricaSuglasnosti
                                    .getRow(1)
                                    .getResizedRange(0, -1)
                                    .getOffsetRange(0, 1);

                                matricaSuglasnostiTezineRange.getColumnsAfter(1).values = [["Suma"]];

                                const matricaSuglasnosiSumaRange = matricaSuglasnostiValuesRange.getColumnsAfter(1);

                                matricaSuglasnostiTezineRange.load(LOAD_ADDRESS);
                                matricaSuglasnosiSumaRange.load(LOAD_ADDRESS);
                                await context.sync();
                                const matricaSuglasnostiTezineAddress = rangeToAddress(
                                    matricaSuglasnostiTezineRange.address
                                );

                                const matricaSuglasnostiSumaAddr = rangeToAddress(matricaSuglasnosiSumaRange.address);

                                for (let col = 0; col < kriterijiCount; col++) {
                                    const tezinaAddr = new Address(
                                        matricaSuglasnostiTezineAddress.from.add({ columnCount: col })
                                    );
                                    tezinaAddr.freezeRow();

                                    let mainRelativeRow: number = 0;
                                    let otherRelativeRow: number = 1;

                                    for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                        if (row % (alternativeCount - 1) === 0) {
                                            mainRelativeRow += 1;
                                            otherRelativeRow = 1;
                                        }

                                        if (mainRelativeRow === otherRelativeRow) {
                                            otherRelativeRow += 1;
                                        }

                                        if (col === 0) {
                                            matricaSuglasnostiValuesRange.getColumnsAfter(1).getRow(row).values = [
                                                [
                                                    `=SUM(${tezinaAddr.add({ rowCount: row + 1 })}:${tezinaAddr.add({
                                                        columnCount: kriterijiCount - 1,
                                                        rowCount: row + 1,
                                                    })})`,
                                                ],
                                            ];
                                        }

                                        matricaSuglasnostiValuesRange.getColumn(col).getRow(row).values = [
                                            [
                                                `=IF(${ponderNormMatValuesAddr.from.add({
                                                    rowCount: mainRelativeRow - 1,
                                                    columnCount: col,
                                                })}>=${ponderNormMatValuesAddr.from.add({
                                                    rowCount: otherRelativeRow - 1,
                                                    columnCount: col,
                                                })}, ${tezinaAddr.getValue()}, 0)`,
                                            ],
                                        ];
                                        otherRelativeRow += 1;
                                    }
                                }

                                const zapravoMatricaSuglanosti = matricaSuglasnosti
                                    .getRowsBelow(1)
                                    .getColumnsBefore(1)
                                    .getColumnsAfter(alternativeCount + 1)
                                    .getResizedRange(alternativeCount, 0)
                                    .getOffsetRange(1, 0);
                                zapravoMatricaSuglanosti
                                    .getRow(0)
                                    .getColumn(1)
                                    .copyFrom(alternativeRange, undefined, undefined, true);
                                zapravoMatricaSuglanosti.getColumn(0).getRow(1).copyFrom(alternativeRange);
                                zapravoMatricaSuglanosti.getCell(0, 0).values = [["C"]];

                                const zapravoMatricaSuglasnostiValuesRange = zapravoMatricaSuglanosti
                                    .getResizedRange(-1, -1)
                                    .getOffsetRange(1, 1);

                                let rowAccumulator = 0;
                                for (let row = 0; row < alternativeCount; row++) {
                                    for (let col = 0; col < alternativeCount; col++) {
                                        if (row === col) {
                                            zapravoMatricaSuglasnostiValuesRange.getCell(row, col).values = [[`*`]];
                                        } else {
                                            zapravoMatricaSuglasnostiValuesRange.getCell(row, col).values = [
                                                [
                                                    `=${matricaSuglasnostiSumaAddr.from.add({
                                                        rowCount: rowAccumulator,
                                                    })}`,
                                                ],
                                            ];
                                            rowAccumulator += 1;
                                        }
                                    }
                                }

                                // WARN: Matrica nesuglasnosti

                                const matricaNesuglasnostiNazivnik = matricaSuglasnosti
                                    .getRowsBelow(1)
                                    .getResizedRange(1 + alternativeCount * (alternativeCount - 1), 0)
                                    .getOffsetRange(alternativeCount + 5, 0);

                                matricaNesuglasnostiNazivnik.copyFrom(skupNesuglasnosti);

                                const matricaNesuglasnostiNazivnikValuesRange = matricaNesuglasnostiNazivnik
                                    .getResizedRange(-2, -1)
                                    .getOffsetRange(2, 1);

                                matricaNesuglasnostiNazivnik.getRowsAbove(2).getCell(0, 0).values = [
                                    ["6) Matrica nesuglasnosti"],
                                ];

                                matricaNesuglasnostiNazivnik.getCell(0, 0).values = [["Nazivnik"]];

                                matricaNesuglasnostiNazivnik.getRow(0).getColumn(1).copyFrom(kriterijiRange);
                                matricaNesuglasnostiNazivnik
                                    .getRow(1)
                                    .copyFrom(tezineRange.getResizedRange(0, 1).getOffsetRange(0, -1));

                                const matricaNesuglasnostiNazivnikTezineRange = matricaNesuglasnostiNazivnik
                                    .getRow(1)
                                    .getResizedRange(0, -1)
                                    .getOffsetRange(0, 1);
                                matricaNesuglasnostiNazivnikTezineRange.getColumnsAfter(1).values = [["Max"]];

                                matricaNesuglasnostiNazivnikTezineRange.load(LOAD_ADDRESS);
                                matricaNesuglasnostiNazivnikValuesRange.load(LOAD_ADDRESS);
                                await context.sync();

                                const matricaNesuglasnostiNazivnikValuesAddr = rangeToAddress(
                                    matricaNesuglasnostiNazivnikValuesRange.address
                                );

                                const matricaNesuglasnostiNazivnikMaxRange =
                                    matricaNesuglasnostiNazivnikValuesRange.getColumnsAfter(1);

                                for (let col = 0; col < kriterijiCount; col++) {
                                    for (let col = 0; col < kriterijiCount; col++) {
                                        let mainRelativeRow: number = 0;
                                        let otherRelativeRow: number = 1;
                                        for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                            if (row % (alternativeCount - 1) === 0) {
                                                mainRelativeRow += 1;
                                                otherRelativeRow = 1;
                                            }

                                            if (mainRelativeRow === otherRelativeRow) {
                                                otherRelativeRow += 1;
                                            }

                                            if (col === 0) {
                                                matricaNesuglasnostiNazivnikMaxRange.getRow(row).values = [
                                                    [
                                                        `=MAX(${matricaNesuglasnostiNazivnikValuesAddr.from.add({
                                                            rowCount: row,
                                                        })}:${matricaNesuglasnostiNazivnikValuesAddr.from.add({
                                                            rowCount: row,
                                                            columnCount: kriterijiCount - 1,
                                                        })})`,
                                                    ],
                                                ];
                                            }

                                            matricaNesuglasnostiNazivnikValuesRange.getColumn(col).getRow(row).values =
                                                [
                                                    [
                                                        `=ABS(${ponderNormMatValuesAddr.from.add({
                                                            rowCount: mainRelativeRow - 1,
                                                            columnCount: col,
                                                        })}-${ponderNormMatValuesAddr.from.add({
                                                            rowCount: otherRelativeRow - 1,
                                                            columnCount: col,
                                                        })})`,
                                                    ],
                                                ];
                                            otherRelativeRow += 1;
                                        }
                                    }
                                }

                                const matricaNesuglasnostiBrojnik = matricaNesuglasnostiNazivnik
                                    .getOffsetRange(0, kriterijiCount + 1 + 3)
                                    .getResizedRange(0, 1);

                                matricaNesuglasnostiBrojnik.copyFrom(
                                    matricaNesuglasnostiNazivnik.getResizedRange(0, 1)
                                );

                                const matricaNesuglasnostiBrojnikValuesRange = matricaNesuglasnostiBrojnik
                                    .getResizedRange(-2, -1)
                                    .getOffsetRange(2, 1);

                                matricaNesuglasnostiBrojnik.getCell(0, 0).values = [["Brojnik"]];

                                const matricaNesuglasnostiBrojnikMaxRange = matricaNesuglasnostiBrojnikValuesRange
                                    .getColumnsAfter(1)
                                    .getOffsetRange(0, -1);

                                for (let col = 0; col < kriterijiCount; col++) {
                                    for (let col = 0; col < kriterijiCount; col++) {
                                        let mainRelativeRow: number = 0;
                                        let otherRelativeRow: number = 1;
                                        for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                            if (row % (alternativeCount - 1) === 0) {
                                                mainRelativeRow += 1;
                                                otherRelativeRow = 1;
                                            }

                                            if (mainRelativeRow === otherRelativeRow) {
                                                otherRelativeRow += 1;
                                            }

                                            matricaNesuglasnostiBrojnikValuesRange.getColumn(col).getRow(row).values = [
                                                [
                                                    `=IF(${ponderNormMatValuesAddr.from.add({
                                                        rowCount: mainRelativeRow - 1,
                                                        columnCount: col,
                                                    })}<${ponderNormMatValuesAddr.from.add({
                                                        rowCount: otherRelativeRow - 1,
                                                        columnCount: col,
                                                    })}, ${matricaNesuglasnostiNazivnikValuesAddr.from.add({
                                                        columnCount: col,
                                                        rowCount: row,
                                                    })}, 0)`,
                                                ],
                                            ];
                                            otherRelativeRow += 1;
                                        }
                                    }
                                }

                                const podijeljenoTable = matricaNesuglasnostiBrojnik
                                    .getColumnsAfter(1)
                                    .getOffsetRange(2, 2)
                                    .getResizedRange(-2, 1);
                                podijeljenoTable.copyFrom(matricaNesuglasnostiBrojnikValuesRange.getColumnsBefore(1));

                                matricaNesuglasnostiNazivnikMaxRange.load(LOAD_ADDRESS);
                                matricaNesuglasnostiBrojnikMaxRange.load(LOAD_ADDRESS);
                                podijeljenoTable.load(LOAD_ADDRESS);
                                await context.sync();

                                const mnNazivnikMaxAddr = rangeToAddress(matricaNesuglasnostiNazivnikMaxRange.address);
                                const mnBrojnikMaxAddr = rangeToAddress(matricaNesuglasnostiBrojnikMaxRange.address);

                                for (let row = 0; row < alternativeCount * (alternativeCount - 1); row++) {
                                    podijeljenoTable.getRow(row).getColumn(1).values = [
                                        [
                                            `=${mnBrojnikMaxAddr.from.add({
                                                rowCount: row,
                                            })}/${mnNazivnikMaxAddr.from.add({ rowCount: row })}`,
                                        ],
                                    ];
                                }
                                const zapravoMatricaNesuglasnosti = matricaNesuglasnostiNazivnik
                                    .getRowsBelow(1)
                                    .getColumnsBefore(1)
                                    .getColumnsAfter(alternativeCount + 1)
                                    .getResizedRange(alternativeCount, 0)
                                    .getOffsetRange(1, 0);
                                zapravoMatricaNesuglasnosti
                                    .getRow(0)
                                    .getColumn(1)
                                    .copyFrom(alternativeRange, undefined, undefined, true);
                                zapravoMatricaNesuglasnosti.getColumn(0).getRow(1).copyFrom(alternativeRange);
                                zapravoMatricaNesuglasnosti.getCell(0, 0).values = [["D"]];

                                const zapravoMatricaNesuglasnostiValuesRange = zapravoMatricaNesuglasnosti
                                    .getResizedRange(-1, -1)
                                    .getOffsetRange(1, 1);

                                rowAccumulator = 0;
                                const podijeljenoTableAddr = rangeToAddress(podijeljenoTable.address);
                                for (let row = 0; row < alternativeCount; row++) {
                                    for (let col = 0; col < alternativeCount; col++) {
                                        if (row === col) {
                                            zapravoMatricaNesuglasnostiValuesRange.getCell(row, col).values = [[`*`]];
                                        } else {
                                            zapravoMatricaNesuglasnostiValuesRange.getCell(row, col).values = [
                                                [
                                                    `=${podijeljenoTableAddr.from.add({
                                                        rowCount: rowAccumulator,
                                                        columnCount: 1,
                                                    })}`,
                                                ],
                                            ];
                                            rowAccumulator += 1;
                                        }
                                    }
                                }

                                // WARN: Matrica dominacije po suglasnosti

                                const matricaDominacijePoSuglasnostiRange = zapravoMatricaNesuglasnosti
                                    .getRowsBelow(1)
                                    .getResizedRange(alternativeCount, 0)
                                    .getOffsetRange(5, 0);

                                matricaDominacijePoSuglasnostiRange.getRowsAbove(4).getCell(0, 0).values = [
                                    ["7) Matrica dominacije po suglasnosti"],
                                ];

                                const pragSuglasnosti = matricaDominacijePoSuglasnostiRange
                                    .getRowsAbove(1)
                                    .getColumnsBefore(1)
                                    .getOffsetRange(-1, 1)
                                    .getResizedRange(0, 1);

                                matricaDominacijePoSuglasnostiRange.copyFrom(zapravoMatricaNesuglasnosti);
                                matricaDominacijePoSuglasnostiRange.getCell(0, 0).values = [["E"]];

                                zapravoMatricaSuglasnostiValuesRange.load(LOAD_ADDRESS);
                                await context.sync();
                                const matricaSuglasnostiValAddr = rangeToAddress(
                                    zapravoMatricaSuglasnostiValuesRange.address
                                );

                                pragSuglasnosti.values = [
                                    [
                                        "Prag suglasnosti",
                                        `=AVERAGE(${matricaSuglasnostiValAddr.from.getValue()}:${matricaSuglasnostiValAddr.from.add(
                                            { columnCount: alternativeCount - 1, rowCount: alternativeCount - 1 }
                                        )})`,
                                    ],
                                ];

                                const matricaDominacijeSuglasnostiValRange = matricaDominacijePoSuglasnostiRange
                                    .getResizedRange(-1, -1)
                                    .getOffsetRange(1, 1);
                                matricaDominacijeSuglasnostiValRange.load(LOAD_ADDRESS);
                                pragSuglasnosti.load(LOAD_ADDRESS);
                                await context.sync();

                                const pragSuglasnosiAddr = new Address(
                                    rangeToAddress(pragSuglasnosti.address).from.add({ columnCount: 1 })
                                );
                                pragSuglasnosiAddr.freezeRow();
                                pragSuglasnosiAddr.freezeColumn();

                                for (let row = 0; row < alternativeCount; row++) {
                                    for (let col = 0; col < alternativeCount; col++) {
                                        if (row === col) {
                                            matricaDominacijeSuglasnostiValRange.getCell(row, col).values = [["*"]];
                                        } else {
                                            matricaDominacijeSuglasnostiValRange.getCell(row, col).values = [
                                                [
                                                    `=IF(${matricaSuglasnostiValAddr.from.add({
                                                        columnCount: col,
                                                        rowCount: row,
                                                    })}>=${pragSuglasnosiAddr.getValue()}, 1, 0)`,
                                                ],
                                            ];
                                        }
                                    }
                                }

                                // WARN: Matrica dominacije po nesuglasnosti
                                const matricaDominacijePoNesuglasnostiRange = matricaDominacijePoSuglasnostiRange
                                    .getRowsBelow(1)
                                    .getResizedRange(alternativeCount, 0)
                                    .getOffsetRange(5, 0);

                                matricaDominacijePoNesuglasnostiRange.getRowsAbove(4).getCell(0, 0).values = [
                                    ["8) Matrica dominacije po nesuglasnosti"],
                                ];

                                const pragNesuglasnosti = matricaDominacijePoNesuglasnostiRange
                                    .getRowsAbove(1)
                                    .getColumnsBefore(1)
                                    .getOffsetRange(-1, 1)
                                    .getResizedRange(0, 1);

                                matricaDominacijePoNesuglasnostiRange.copyFrom(zapravoMatricaNesuglasnosti);
                                matricaDominacijePoNesuglasnostiRange.getCell(0, 0).values = [["F"]];

                                zapravoMatricaNesuglasnosti.load(LOAD_ADDRESS);
                                await context.sync();
                                const matricaNeuglasnostiValAddr = rangeToAddress(zapravoMatricaNesuglasnosti.address);

                                pragNesuglasnosti.values = [
                                    [
                                        "Prag nesuglasnosti",
                                        `=AVERAGE(${matricaNeuglasnostiValAddr.from.add({
                                            columnCount: 1,
                                            rowCount: 1,
                                        })}:${matricaNeuglasnostiValAddr.from.add({
                                            columnCount: alternativeCount,
                                            rowCount: alternativeCount,
                                        })})`,
                                    ],
                                ];

                                const matricaDominacijeNesuglasnostiValRange = matricaDominacijePoNesuglasnostiRange
                                    .getResizedRange(-1, -1)
                                    .getOffsetRange(1, 1);
                                matricaDominacijeNesuglasnostiValRange.load(LOAD_ADDRESS);
                                pragNesuglasnosti.load(LOAD_ADDRESS);
                                await context.sync();

                                const pragNesuglasnosiAddr = new Address(
                                    rangeToAddress(pragNesuglasnosti.address).from.add({ columnCount: 1 })
                                );
                                pragNesuglasnosiAddr.freezeRow();
                                pragNesuglasnosiAddr.freezeColumn();

                                for (let row = 0; row < alternativeCount; row++) {
                                    for (let col = 0; col < alternativeCount; col++) {
                                        if (row === col) {
                                            matricaDominacijeNesuglasnostiValRange.getCell(row, col).values = [["*"]];
                                        } else {
                                            matricaDominacijeNesuglasnostiValRange.getCell(row, col).values = [
                                                [
                                                    `=IF(${matricaNeuglasnostiValAddr.from.add({
                                                        columnCount: col + 1,
                                                        rowCount: row + 1,
                                                    })}<=${pragNesuglasnosiAddr.getValue()}, 1, 0)`,
                                                ],
                                            ];
                                        }
                                    }
                                }

                                // WARN: Agregirana matrica
                                const agregiranaMatrica = matricaDominacijePoNesuglasnostiRange
                                    .getRowsBelow(1)
                                    .getResizedRange(alternativeCount, 0)
                                    .getOffsetRange(3, 0);

                                agregiranaMatrica.getRowsAbove(2).getCell(0, 0).values = [["9) Agregirana matrica"]];

                                agregiranaMatrica.copyFrom(matricaDominacijePoNesuglasnostiRange);
                                agregiranaMatrica.getCell(0, 0).values = [["G"]];

                                const mdsValAddr = rangeToAddress(matricaDominacijeSuglasnostiValRange.address);
                                const mdnsValAddr = rangeToAddress(matricaDominacijeNesuglasnostiValRange.address);

                                const agregiranaMatricaValues = agregiranaMatrica
                                    .getResizedRange(-1, -1)
                                    .getOffsetRange(1, 1);

                                for (let row = 0; row < alternativeCount; row++) {
                                    for (let col = 0; col < alternativeCount; col++) {
                                        if (row !== col) {
                                            agregiranaMatricaValues.getCell(row, col).values = [
                                                [
                                                    `=${mdsValAddr.from.add({
                                                        columnCount: col,
                                                        rowCount: row,
                                                    })}*${mdnsValAddr.from.add({ columnCount: col, rowCount: row })}`,
                                                ],
                                            ];
                                        }
                                    }
                                }

                                const agregiranaMatricaSumRange = agregiranaMatricaValues.getRowsBelow(1);
                                agregiranaMatricaValues.load(LOAD_ADDRESS);
                                agregiranaMatricaSumRange.load(LOAD_ADDRESS);
                                await context.sync();

                                const agregiranaMatricaValAddr = rangeToAddress(agregiranaMatricaValues.address);
                                for (let col = 0; col < alternativeCount; col++) {
                                    agregiranaMatricaSumRange.getColumn(col).values = [
                                        [
                                            `=COUNTIF(${agregiranaMatricaValAddr.from.add({
                                                columnCount: col,
                                            })}:${agregiranaMatricaValAddr.from.add({
                                                columnCount: col,
                                                rowCount: alternativeCount - 1,
                                            })}, 0)`,
                                        ],
                                    ];
                                }

                                // WARN: Jezgra grafa

                                const jezgraGrafa = agregiranaMatrica
                                    .getRowsBelow(1)
                                    .getOffsetRange(4, 0)
                                    .getColumnsBefore(1)
                                    .getColumnsAfter(1)
                                    .getResizedRange(1, 1);

                                jezgraGrafa.getRowsAbove(2).getCell(0, 0).values = [["10) Jezgra grafa"]];
                                jezgraGrafa.getColumn(0).values = [["Jezgra jedinstvena"], ["Jezgra"]];

                                const jezgraJedinstvena = jezgraGrafa.getCell(0, 1);
                                const jezgra = jezgraGrafa.getCell(1, 1);

                                const agregiranaMatricaSumAddr = rangeToAddress(agregiranaMatricaSumRange.address);

                                jezgraJedinstvena.values = [
                                    [
                                        `=IF(COUNTIF(${agregiranaMatricaSumAddr.from.getValue()}:${agregiranaMatricaSumAddr.from.add(
                                            { columnCount: alternativeCount - 1 }
                                        )}, ${alternativeCount - 1})=1, "DA", "NE")`,
                                    ],
                                ];

                                alternativeRange.load(LOAD_ADDRESS);
                                await context.sync();
                                const alternativeAddr = rangeToAddress(alternativeRange.address);

                                let partialJezgraIfString = "";
                                for (let i = 0; i < alternativeCount; i++) {
                                    const alternativaValueAddr = alternativeAddr.from.add({ rowCount: i });
                                    partialJezgraIfString = partialJezgraIfString.concat(
                                        `${i === 0 ? "" : ","}IF(${agregiranaMatricaSumAddr.from.add({
                                            columnCount: i,
                                        })}=${alternativeCount - 1},${alternativaValueAddr}, "")`
                                    );
                                }

                                jezgra.values = [[`=CONCAT(${partialJezgraIfString})`]];
                            }

                            await context.sync();
                        });
                    }}
                >
                    Generiraj elektru
                </Button>
            </Flex>
        </Container>
    );
}

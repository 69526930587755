import { Button, Center, Checkbox, Container, Flex, TextInput, Title } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import {
    Address,
    COPYTO_CELL,
    LOAD_ADDRESS,
    rangeToAddress,
    registerOnSelectionChangeEventHandler,
    removeOnSelectionChangedEventHandler,
    selectionChange,
} from "../utils/utils";

export default function Rangiranje() {
    const [transpose, setTranspore] = useState<boolean>(false);
    const [selection, _setSelection] = useState<Excel.Range>(undefined);

    const setSelection = useCallback((range: Excel.Range) => {
        _setSelection((old) => {
            (async () => {
                old.context.trackedObjects.remove(old);
                await old.context.sync();
                console.log("Removing old tracked object");
            })();
            range.context.trackedObjects.add(range);
            return range;
        });
    }, []);

    const selectionChangeHandler = useCallback(async (change: Excel.SelectionChangedEventArgs) => {
        setSelection(await selectionChange(change));
    }, []);

    useEffect(() => {
        const result = registerOnSelectionChangeEventHandler(selectionChangeHandler);
        return () => {
            removeOnSelectionChangedEventHandler(result);
        };
    }, [selectionChange]);

    return (
        <Container>
            <Flex direction="column" gap="sm">
                <Center>
                    <Title order={4}>Rangiranje</Title>
                </Center>
                <TextInput label={"Odabrane ćelije"} value={selection ? selection.address : ""} disabled />
                <Checkbox
                    onChange={() => {
                        setTranspore((old) => !old);
                    }}
                    label={"Transponiraj?"}
                />
                <Button
                    color="gray"
                    onClick={() => {
                        Excel.run(selection.context, async (context) => {
                            const newSheet = context.workbook.worksheets.add();
                            newSheet.activate();
                            newSheet.getRange(COPYTO_CELL).copyFrom(selection, undefined, undefined, transpose);

                            const columnCount = transpose ? selection.rowCount : selection.columnCount;
                            const rowCount = transpose ? selection.columnCount : selection.rowCount;

                            const mainTable = newSheet
                                .getRange(COPYTO_CELL)
                                .getResizedRange(rowCount - 1, columnCount - 1);
                            const mainTableValuesRange = mainTable.getOffsetRange(1, 1).getResizedRange(-1, -1);
                            const mainTableValuesStartCell = mainTableValuesRange.getCell(0, 0).load(LOAD_ADDRESS);
                            await context.sync();
                            const mainTableValuesStartCellAddress = new Address(mainTableValuesStartCell.address);

                            // WARN: Suma
                            const sumRange = mainTableValuesRange.getRowsBelow(1);
                            sumRange.getColumnsBefore(1).values = [["Suma"]];

                            const sumValues = [];
                            for (let column = 0; column < columnCount - 1; column++) {
                                sumValues.push(
                                    `=SUM(${mainTableValuesStartCellAddress.add({
                                        columnCount: column,
                                    })}:${mainTableValuesStartCellAddress.add({
                                        columnCount: column,
                                        rowCount: rowCount - 2,
                                    })})`
                                );
                            }
                            sumRange.values = [sumValues];

                            // WARN: Normalizacija po zbroju
                            const normalizacijaPoZbrojuRange = sumRange
                                .getResizedRange(0, 1)
                                .getOffsetRange(0, -1)
                                .getOffsetRange(4, 0)
                                .getResizedRange(rowCount - 1, 0);

                            normalizacijaPoZbrojuRange
                                .getRowsAbove(1)
                                .getColumnsBefore(1)
                                .getColumnsAfter(1)
                                .getOffsetRange(-1, 0).values = [["Normalizacija po zbroju"]];

                            normalizacijaPoZbrojuRange.copyFrom(mainTable);

                            const normalizacijaPoZbrojuValuesRange = normalizacijaPoZbrojuRange
                                .getOffsetRange(1, 1)
                                .getResizedRange(-1, -1);
                            normalizacijaPoZbrojuValuesRange.load(LOAD_ADDRESS);
                            await context.sync();

                            for (let column = 0; column < columnCount - 1; column++) {
                                const columnValues = [];
                                const sumAddress = new Address(
                                    mainTableValuesStartCellAddress.add({ columnCount: column, rowCount: rowCount - 1 })
                                );
                                sumAddress.freezeRow();
                                for (let row = 0; row < rowCount - 1; row++) {
                                    columnValues.push([
                                        `=${mainTableValuesStartCellAddress.add({
                                            columnCount: column,
                                            rowCount: row,
                                        })}/${sumAddress.getValue()}`,
                                    ]);
                                }
                                normalizacijaPoZbrojuValuesRange.getColumn(column).values = columnValues;
                            }

                            const normalizacijaPoZbrojuValuesAddress = rangeToAddress(
                                normalizacijaPoZbrojuValuesRange.address
                            );
                            const ukupniPrioritetiPoZbrojuRange = normalizacijaPoZbrojuRange.getColumnsAfter(1);
                            ukupniPrioritetiPoZbrojuRange.getRow(0).values = [["Ukupni prioriteti"]];
                            const ukupniPrioritetiPoZbrojuValuesRange = ukupniPrioritetiPoZbrojuRange
                                .getOffsetRange(1, 0)
                                .getResizedRange(-1, 0);

                            const ukupniPrioritetiPoZbrojuColumnValues = [];
                            for (let row = 0; row < rowCount - 1; row++) {
                                ukupniPrioritetiPoZbrojuColumnValues.push([
                                    `=AVERAGE(${normalizacijaPoZbrojuValuesAddress.from.add({
                                        rowCount: row,
                                    })}:${normalizacijaPoZbrojuValuesAddress.from.add({
                                        rowCount: row,
                                        columnCount: columnCount - 2,
                                    })})`,
                                ]);
                            }
                            ukupniPrioritetiPoZbrojuValuesRange.values = ukupniPrioritetiPoZbrojuColumnValues;

                            const odlukaNorm = ukupniPrioritetiPoZbrojuValuesRange.getRowsBelow(1).getOffsetRange(1, 0);
                            const odlukaUPAddressRange = `${normalizacijaPoZbrojuValuesAddress.from.add({
                                columnCount: columnCount - 1,
                            })}:${normalizacijaPoZbrojuValuesAddress.from.add({
                                columnCount: columnCount - 1,
                                rowCount: rowCount - 2,
                            })}`;
                            odlukaNorm.values = [
                                [
                                    `=INDEX(${normalizacijaPoZbrojuValuesAddress.from.add({
                                        columnCount: -1,
                                    })}:${normalizacijaPoZbrojuValuesAddress.from.add({
                                        columnCount: -1,
                                        rowCount: rowCount - 2,
                                    })}, MATCH(TRUE, MAX(${odlukaUPAddressRange})=${odlukaUPAddressRange}, 0))`,
                                ],
                            ];
                            odlukaNorm.getOffsetRange(0, -1).values = [["Odluka"]];

                            // WARN: Rangiranje temelju rangova

                            const rangiranjeTemeljuRangovaRange = normalizacijaPoZbrojuRange
                                .getRowsBelow(1)
                                .getOffsetRange(5, 0)
                                .getResizedRange(rowCount - 1, 0);
                            rangiranjeTemeljuRangovaRange
                                .getRowsAbove(1)
                                .getColumnsBefore(1)
                                .getColumnsAfter(1)
                                .getOffsetRange(-1, 0).values = [["Metoda rangiranja temelju rangova"]];

                            rangiranjeTemeljuRangovaRange.copyFrom(mainTable);
                            const rangiranjeTemeljuRangovaValuesRange = rangiranjeTemeljuRangovaRange
                                .getOffsetRange(1, 1)
                                .getResizedRange(-1, -1);

                            rangiranjeTemeljuRangovaValuesRange.load(LOAD_ADDRESS);
                            await context.sync();
                            const rangiranjeTemeljuRangovaValuesAddress = rangeToAddress(
                                rangiranjeTemeljuRangovaValuesRange.address
                            );

                            for (let column = 0; column < columnCount - 1; column++) {
                                const rangValues = [];
                                const rangRangeAddr = `${mainTableValuesStartCellAddress.add({
                                    columnCount: column,
                                    freezeRows: true,
                                })}:${mainTableValuesStartCellAddress.add({
                                    columnCount: column,
                                    rowCount: rowCount - 2,
                                    freezeRows: true,
                                })}`;
                                for (let row = 0; row < rowCount - 1; row++) {
                                    rangValues.push([
                                        `=RANK(${mainTableValuesStartCellAddress.add({
                                            columnCount: column,
                                            rowCount: row,
                                        })},${rangRangeAddr})`,
                                    ]);
                                }
                                rangiranjeTemeljuRangovaValuesRange.getColumn(column).values = rangValues;
                            }

                            // WARN: Bodovanje

                            const bodovanjeRange = rangiranjeTemeljuRangovaRange
                                .getRowsBelow(1)
                                .getOffsetRange(4, 0)
                                .getColumnsBefore(1)
                                .getColumnsAfter(1)
                                .getResizedRange(rowCount, rowCount - 1);
                            bodovanjeRange
                                .getRowsAbove(1)
                                .getColumnsBefore(1)
                                .getColumnsAfter(1)
                                .getOffsetRange(-1, 0).values = [["Bodovanje"]];

                            bodovanjeRange
                                .getOffsetRange(1, 0)
                                .copyFrom(mainTable.getResizedRange(0, -(rowCount - (rowCount - 1))));

                            const bodovanjeValuesRange = bodovanjeRange.getOffsetRange(2, 1).getResizedRange(-2, -1);
                            bodovanjeValuesRange.load(LOAD_ADDRESS);
                            await context.sync();
                            const bodovanjeValuesAddr = rangeToAddress(bodovanjeValuesRange.address);

                            const bodoviRange = bodovanjeRange.getRowsAbove(1).getRowsBelow(1);
                            const rangoviRange = bodoviRange.getRowsBelow(1);

                            const bodoviValues = [];
                            const rangoviValues = [];
                            for (let column = 0; column < rowCount - 1; column++) {
                                bodoviValues.push(column);
                                rangoviValues.push(rowCount - 1 - column);
                            }

                            bodoviRange.values = [["Bodovi", ...bodoviValues]];
                            rangoviRange.values = [["Rangovi", ...rangoviValues]];

                            for (let row = 0; row < rowCount - 1; row++) {
                                const rangiranjeAddrRange = `${rangiranjeTemeljuRangovaValuesAddress.from.add({
                                    rowCount: row,
                                    freezeColumn: true,
                                })}:${rangiranjeTemeljuRangovaValuesAddress.from.add({
                                    columnCount: columnCount - 2,
                                    rowCount: row,
                                    freezeColumn: true,
                                })}`;

                                const values = [];
                                for (let column = 0; column < rowCount - 1; column++) {
                                    const rangoviAddr = bodovanjeValuesAddr.from.add({
                                        columnCount: column,
                                        rowCount: -1,
                                        freezeRows: true,
                                    });
                                    values.push(`=COUNTIF(${rangiranjeAddrRange},${rangoviAddr})`);
                                }
                                bodovanjeValuesRange.getRow(row).values = [values];
                            }

                            const uPBodovanje = bodovanjeRange
                                .getColumnsAfter(1)
                                .getResizedRange(-1, 0)
                                .getOffsetRange(1, 0);
                            uPBodovanje.getRow(0).values = [["Ukupni prioriteti"]];
                            const uPBodovanjeValuesRange = uPBodovanje.getResizedRange(-1, 0).getOffsetRange(1, 0);
                            uPBodovanjeValuesRange.load(LOAD_ADDRESS);
                            await context.sync();

                            const bodoviRangeString = `${bodovanjeValuesAddr.from.add({
                                rowCount: -2,
                                freezeRows: true,
                            })}:${bodovanjeValuesAddr.from.add({
                                rowCount: -2,
                                columnCount: rowCount - 2,
                                freezeRows: true,
                            })}`;
                            const uPValues = [];
                            for (let row = 0; row < rowCount - 1; row++) {
                                const tableBodovi = `${bodovanjeValuesAddr.from.add({
                                    rowCount: row,
                                })}:${bodovanjeValuesAddr.from.add({
                                    rowCount: row,
                                    columnCount: rowCount - 2,
                                })}`;
                                uPValues.push([`=SUMPRODUCT(${bodoviRangeString},${tableBodovi})`]);
                            }

                            uPBodovanjeValuesRange.values = uPValues;

                            const odlukaBod = uPBodovanjeValuesRange.getRowsBelow(1).getOffsetRange(1, 0);
                            const uPBodovanjeAddress = rangeToAddress(uPBodovanjeValuesRange.address);
                            const upRangeString = `${uPBodovanjeAddress.from.getValue()}:${uPBodovanjeAddress.from.add({
                                rowCount: rowCount - 2,
                            })}`;
                            odlukaBod.values = [
                                [
                                    `=INDEX(${bodovanjeValuesAddr.from.add({
                                        columnCount: -1,
                                    })}:${bodovanjeValuesAddr.from.add({
                                        columnCount: -1,
                                        rowCount: rowCount - 2,
                                    })}, MATCH(TRUE, MAX(${upRangeString})=${upRangeString}, 0))`,
                                ],
                            ];
                            odlukaBod.getOffsetRange(0, -1).values = [["Odluka"]];

                            await context.sync();
                        });
                    }}
                >
                    Rangiraj
                </Button>
            </Flex>
        </Container>
    );
}

import { Address, LOAD_ADDRESS, makeRangeString, RangeToAddress, rangeToAddress } from "./utils";

export const fillRangColumn = (table: Excel.Range, alternativesCount: number): void => {
    table.getColumn(0).getResizedRange(-1, 0).getOffsetRange(1, 0).values = Array.from(
        { length: alternativesCount },
        (_v, idx) => {
            return [`${idx + 1}`];
        }
    );
};

export const makeSWINGHeader = (table: Excel.Range) => {
    table.getRow(0).values = [["Rang", "Kriterij", "Bodovi", "Težine"]];
};

export const makeRRHeader = (table: Excel.Range) => {
    table.getRow(0).values = [["Rang", "Kriterij", "Recipročni rang", "Težine"]];
};

export const makeROCHeader = (table: Excel.Range) => {
    table.getRow(0).values = [["Rang", "Kriterij", "Recipročni rang", "Kumulativni niz", "Težine"]];
};

export type Normalizacija = {
    sheet: Excel.Worksheet;
    mainTable: {
        table: Excel.Range;
        rowCount: number;
        columnCount: number;
        minMaxRange: Excel.Range;
        valuesStartCell: Address;
    };
    weightRange: Excel.Range;
    startingPosition: string;
    context: Excel.RequestContext;
    kriterijiCount: number;
    alternativeCount: number;
    type: NormalizacijaType;
};

export enum NormalizacijaType {
    Najmanja,
    Najveca,
    Raspon,
}

enum MinMaxKriterij {
    Min,
    Max,
}

export const makeNormalizacijaTable = async (norm: Normalizacija) => {
    norm.sheet.getRange(norm.startingPosition).copyFrom(norm.mainTable.table);
    const normTable = norm.sheet
        .getRange(norm.startingPosition)
        .getResizedRange(norm.mainTable.rowCount - 1, norm.mainTable.columnCount);
    const tableHeader = normTable.getRow(0);
    const tezineRow = normTable.getRow(1);
    tezineRow.load("values");
    norm.weightRange.load(LOAD_ADDRESS);
    await norm.context.sync();
    tableHeader.values = tezineRow.values;

    const weightRangeAddress = rangeToAddress(norm.weightRange.address);
    const tezineRowColumnCount = norm.kriterijiCount + 2;
    for (let i = 0; i < tezineRowColumnCount; i++) {
        if (i === 0) {
            tezineRow.getColumn(i).values = [[`Težine`]];
        }
        if (i + 1 === tezineRowColumnCount) {
            tezineRow.getColumn(i).values = [[`Ukupni prioriteti`]];
        } else {
            tezineRow.getColumn(i).values = [[`=${weightRangeAddress.from.add({ rowCount: i - 1 })}`]];
        }
    }

    const minMaxKriteriji = await extractMinMax(norm.mainTable.minMaxRange, norm.context);
    const valuesRange = normTable.getResizedRange(-1, -1).getOffsetRange(1, 1);

    for (let i = 0; i < norm.kriterijiCount; i++) {
        const mainTableRange = `${norm.mainTable.valuesStartCell.add({
            columnCount: i,
        })}:${norm.mainTable.valuesStartCell.add({ columnCount: i, rowCount: norm.alternativeCount - 1 })}`;
        const mainTableRangeAddresses = rangeToAddress(mainTableRange);
        mainTableRangeAddresses.from.freezeRow();
        mainTableRangeAddresses.to.freezeRow();

        for (let j = 1; j <= norm.alternativeCount; j++) {
            const kriterij = minMaxKriteriji[i];
            switch (norm.type) {
                case NormalizacijaType.Najmanja: {
                    valuesRange.getColumn(i).getRow(j).values = [
                        [najmanjaVrijednost(mainTableRangeAddresses, kriterij, j - 1)],
                    ];
                    norm.sheet.getRange(norm.startingPosition).getOffsetRange(-1, 0).values = [
                        ["Normalizacija (najmanja vrijednost)"],
                    ];
                    break;
                }
                case NormalizacijaType.Najveca: {
                    valuesRange.getColumn(i).getRow(j).values = [
                        [najvecaVrijednost(mainTableRangeAddresses, kriterij, j - 1)],
                    ];
                    norm.sheet.getRange(norm.startingPosition).getOffsetRange(-1, 0).values = [
                        ["Normalizacija (najveca vrijednost)"],
                    ];
                    break;
                }
                case NormalizacijaType.Raspon: {
                    valuesRange.getColumn(i).getRow(j).values = [
                        [rasponVrijednosti(mainTableRangeAddresses, kriterij, j - 1)],
                    ];
                    norm.sheet.getRange(norm.startingPosition).getOffsetRange(-1, 0).values = [
                        ["Normalizacija (raspon vrijednosti)"],
                    ];
                    break;
                }
            }
        }
    }

    const tezineValuesRange = tezineRow.getOffsetRange(0, 1).getResizedRange(0, -2);
    tezineValuesRange.load(LOAD_ADDRESS);
    await norm.context.sync();

    const tezineValuesRangeAddress = rangeToAddress(tezineValuesRange.address);
    tezineValuesRangeAddress.from.freezeRow();
    tezineValuesRangeAddress.to.freezeRow();

    const ukupniPrioritetiColumn = valuesRange
        .getColumn(norm.kriterijiCount)
        .getOffsetRange(1, 0)
        .getResizedRange(-1, 0);
    for (let i = 0; i < norm.alternativeCount; i++) {
        const valuesRow = valuesRange.getRow(i + 1).getResizedRange(0, -1);
        valuesRow.load(LOAD_ADDRESS);
        await norm.context.sync();
        const rowAddress = rangeToAddress(valuesRow.address);

        ukupniPrioritetiColumn.getRow(i).values = [
            [
                `=SUMPRODUCT(${makeRangeString(
                    tezineValuesRangeAddress.from,
                    tezineValuesRangeAddress.to
                )},${makeRangeString(rowAddress.from, rowAddress.to)})`,
            ],
        ];
    }
};

const extractMinMax = async (minMaxRange: Excel.Range, context: Excel.RequestContext): Promise<MinMaxKriterij[]> => {
    minMaxRange.load("values");
    await context.sync();
    return minMaxRange.values[0].map((val: string) => {
        const current = val.toLowerCase();
        if (current === "min" || current === "minimum") {
            return MinMaxKriterij.Min;
        } else {
            return MinMaxKriterij.Max;
        }
    });
};

const najmanjaVrijednost = (range: RangeToAddress, kriterij: MinMaxKriterij, offset: number): string => {
    if (kriterij === MinMaxKriterij.Min) {
        return `=MIN(${makeRangeString(range.from, range.to)}/${range.from.add({ rowCount: offset })})`;
    } else {
        return `=1-MIN(${makeRangeString(range.from, range.to)}/${range.from.add({ rowCount: offset })})`;
    }
};

const najvecaVrijednost = (range: RangeToAddress, kriterij: MinMaxKriterij, offset: number): string => {
    if (kriterij === MinMaxKriterij.Min) {
        return `=1-${range.from.add({ rowCount: offset })}/MAX(${makeRangeString(range.from, range.to)})`;
    } else {
        return `=${range.from.add({ rowCount: offset })}/MAX(${makeRangeString(range.from, range.to)})`;
    }
};

const rasponVrijednosti = (range: RangeToAddress, kriterij: MinMaxKriterij, offset: number): string => {
    const rangeString = makeRangeString(range.from, range.to);
    const cell = range.from.add({ rowCount: offset });
    if (kriterij === MinMaxKriterij.Min) {
        return `=(MAX(${rangeString})-${cell})/(MAX(${rangeString})-MIN(${rangeString}))`;
    } else {
        return `=1-(MAX(${rangeString})-${cell})/(MAX(${rangeString})-MIN(${rangeString}))`;
    }
};

export const COPYTO_CELL = "B2";

export const LOAD_ADDRESS = "address";
export const LOAD_ROW_COUNT = "rowCount";
export const LOAD_COLUMN_COUNT = "columnCount";

export type AddToAddress = {
    columnCount?: number;
    rowCount?: number;
    freezeRows?: boolean;
    freezeColumn?: boolean;
};

export type Freeze = {
    freezeColumn?: boolean;
    freezeRow?: boolean;
};

export class Address {
    private columnValue: number;
    private rowValue: number;

    private columnFrozen: boolean;
    private rowFrozen: boolean;

    public constructor(rangeValue: string, { freezeColumn = false, freezeRow = false }: Freeze = {}) {
        let columnValue = "";
        let rowValue = "";
        const lastIndexOfExclamationMark = rangeValue.lastIndexOf("!");
        const value = rangeValue.slice(lastIndexOfExclamationMark + 1);
        for (const character of value.toUpperCase()) {
            if (character === "$") {
                continue;
            }
            if (character.charCodeAt(0) >= "A".charCodeAt(0) && character.charCodeAt(0) <= "Z".charCodeAt(0)) {
                columnValue += character;
            } else {
                rowValue += character;
            }
        }
        this.columnValue = this.columnNameToNumber(columnValue);
        this.rowValue = Number(rowValue);
        this.columnFrozen = freezeColumn;
        this.rowFrozen = freezeRow;
    }

    public getValue(): string {
        return `${this.columnFrozen ? "$" : ""}${this.numberToColumnName(this.columnValue)}${
            this.rowFrozen ? "$" : ""
        }${this.rowValue}`;
    }

    public freezeRow() {
        this.rowFrozen = true;
    }

    public unFreezeRow() {
        this.rowFrozen = false;
    }

    public freezeColumn() {
        this.columnFrozen = true;
    }

    public unFreezeColumn() {
        this.columnFrozen = false;
    }

    public add({
        columnCount: numColumn = 0,
        rowCount: numRows = 0,
        freezeColumn = false,
        freezeRows = false,
    }: AddToAddress): string {
        return `${freezeColumn ? "$" : ""}${this.numberToColumnName(this.columnValue + numColumn)}${
            freezeRows ? "$" : ""
        }${this.rowValue + numRows}`;
    }

    private numberToColumnName(n: number) {
        n -= 1;
        const ordA = "A".charCodeAt(0);
        const ordZ = "Z".charCodeAt(0);
        const len = ordZ - ordA + 1;

        let s = "";
        while (n >= 0) {
            s = String.fromCharCode((n % len) + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    private columnNameToNumber(val: string): number {
        val = val.toUpperCase();
        const base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let result = 0;

        for (let i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
            result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
        }

        return result;
    }
}

export const registerOnSelectionChangeEventHandler = (
    handler: (args: Excel.SelectionChangedEventArgs) => Promise<any>
) => {
    const resultPromise = Excel.run(async (context) => {
        const result = context.workbook.onSelectionChanged.add(handler);
        await context.sync();
        return result;
    });

    return resultPromise;
};

export const removeOnSelectionChangedEventHandler = (
    handlerResult: Promise<OfficeExtension.EventHandlerResult<Excel.SelectionChangedEventArgs>>
) => {
    handlerResult.then((res) => {
        Excel.run(res.context, async (context) => {
            res.remove();
            await context.sync();
        });
    });
};

export const selectionChange = async (change: Excel.SelectionChangedEventArgs) => {
    const range = change.workbook.getSelectedRange();
    range.load([LOAD_ADDRESS, LOAD_ROW_COUNT, LOAD_COLUMN_COUNT]);
    range.context.trackedObjects.add(range);
    await range.context.sync();
    return range;
};

export type RangeToAddress = {
    from: Address;
    to?: Address;
};

export const makeRangeString = (from: Address, to: Address) => {
    return `${from.getValue()}:${to.getValue()}`;
};

export const rangeToAddress = (excelRange: string): RangeToAddress => {
    const lastIndexOfExclamationMark = excelRange.lastIndexOf("!");
    const cleanRange = excelRange.slice(lastIndexOfExclamationMark + 1);
    const split = cleanRange.split(":");
    if (split.length !== 2) {
        return {
            from: new Address(split[0]),
        };
    }
    return {
        from: new Address(split[0]),
        to: new Address(split[1]),
    };
};

import { Button, Center, Checkbox, Container, Flex, NumberInput, TextInput, Title } from "@mantine/core";
import React, { useState } from "react";
import { Address, COPYTO_CELL, LOAD_ADDRESS, makeRangeString } from "../utils/utils";

const CR = {
    3: "0.52",
    4: "0.89",
    5: "1.11",
};

export default function UsporedivanjeUParovima() {
    const [dimenzije, setDimenzije] = useState<number | "">(3);
    const [cr, setCR] = useState<string>(CR[3]);
    const [generateConsistencyCheck, setGenerateConsistencyCheck] = useState<boolean>(false);

    return (
        <Container>
            <Flex direction="column" gap="sm">
                <Center>
                    <Title order={4}>Uspoređivanje u parovima (generiranje tablice)</Title>
                </Center>
                <NumberInput
                    label={"Dimenzije (3x3...)"}
                    value={dimenzije}
                    onChange={(val) => {
                        if (val === 3 || val === 4 || val === 5) {
                            setCR(CR[val]);
                        }
                        setDimenzije(val);
                    }}
                    min={2}
                />
                <Checkbox
                    label="Generiraj konzistentnost ?"
                    onChange={() => {
                        setGenerateConsistencyCheck((old) => !old);
                    }}
                />
                <TextInput
                    disabled={!generateConsistencyCheck}
                    label={"CR (ako je zadani, decimalni broj mora biti napisani s TOČKOM ne zarezom)"}
                    value={cr}
                    onChange={(event) => {
                        setCR(event.target.value);
                    }}
                />
                <Button
                    color="gray"
                    disabled={dimenzije === "" || dimenzije === undefined}
                    onClick={() => {
                        Excel.run(async (context) => {
                            const newSheet = context.workbook.worksheets.add();
                            const dimensions = Number(dimenzije);
                            newSheet.activate();
                            const table = newSheet.getRange(COPYTO_CELL).getResizedRange(dimensions, dimensions);
                            const tableHeaderValesRange = table
                                .getResizedRange(-dimensions, 0)
                                .getResizedRange(0, -1)
                                .getOffsetRange(0, 1);
                            tableHeaderValesRange.values = [
                                Array.from({ length: dimensions }, (_v, idx) => {
                                    return `A${idx + 1}`;
                                }),
                            ];

                            const tableValuesRange = table.getOffsetRange(1, 1).getResizedRange(-1, -1);
                            const rowNameValuesRange = table
                                .getResizedRange(0, -dimensions)
                                .getResizedRange(-1, 0)
                                .getOffsetRange(1, 0);

                            const emptyCellAddress = new Address(COPYTO_CELL);

                            const sumValuesRange = tableValuesRange.getRowsBelow(1);
                            const sumValuesStartAddr = new Address(
                                emptyCellAddress.add({ rowCount: dimensions + 1, columnCount: 1 })
                            );

                            const normAvrgRowNames = rowNameValuesRange.getOffsetRange(2 * dimensions + 1 + 1 + 1, 0);

                            const consistencyValuesRange = tableValuesRange.getOffsetRange(
                                3 * dimensions + 1 + 1 + 1 + 1,
                                0
                            );

                            const weightAddresses: Address[] = [];
                            for (let i = 0; i < dimensions; i++) {
                                const rowName = [`=${emptyCellAddress.add({ columnCount: 1 + i })}`];
                                rowNameValuesRange.getRow(i).values = [rowName];
                                normAvrgRowNames.getRow(i).values = [rowName];

                                tableValuesRange.getColumn(i).getRow(i).values = [[1]];

                                sumValuesRange.getColumn(i).values = [
                                    [
                                        `=SUM(${emptyCellAddress.add({
                                            columnCount: 1 + i,
                                            rowCount: 1,
                                        })}:${emptyCellAddress.add({ columnCount: 1 + i, rowCount: dimensions })})`,
                                    ],
                                ];

                                const normStartAddr = new Address(sumValuesStartAddr.add({ rowCount: 2 + i }));
                                const valuesStartAddr = new Address(
                                    emptyCellAddress.add({ columnCount: 1, rowCount: 1 + i })
                                );

                                const normValuesRange = sumValuesRange
                                    .getRowsBelow(2)
                                    .getResizedRange(-1, 0)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(dimensions - 1, 0);
                                for (let j = 0; j < dimensions; j++) {
                                    const sumAddr = new Address(
                                        sumValuesStartAddr.add({
                                            columnCount: j,
                                        })
                                    );

                                    sumAddr.freezeRow();
                                    normValuesRange.getRow(i).getColumn(j).values = [
                                        [`=${valuesStartAddr.add({ columnCount: j })}/${sumAddr.getValue()}`],
                                    ];

                                    if (generateConsistencyCheck) {
                                        const weightAddr = new Address(
                                            normStartAddr.add({ rowCount: dimensions + 1 + j - i })
                                        );
                                        weightAddr.freezeRow();
                                        weightAddresses.push(weightAddr);
                                        consistencyValuesRange.getRow(i).getColumn(j).values = [
                                            [`=${valuesStartAddr.add({ columnCount: j })}*${weightAddr.getValue()}`],
                                        ];
                                    }

                                    if (j === 0) {
                                        normAvrgRowNames
                                            .getResizedRange(-dimensions + 1, 0)
                                            .getOffsetRange(i, 1).values = [
                                                [
                                                    `=AVERAGE(${normStartAddr.getValue()}:${normStartAddr.add({
                                                        columnCount: dimensions - 1,
                                                    })})`,
                                                ],
                                            ];
                                    }
                                }
                            }

                            if (generateConsistencyCheck) {
                                const consistancyCalcRange = sumValuesRange
                                    .getRowsBelow(2)
                                    .getResizedRange(-1, 0)
                                    .getOffsetRange(1, 0)
                                    .getOffsetRange(dimensions * 3 + 3, 0);
                                const consistancyValuesStartAddr = new Address(
                                    emptyCellAddress.add({
                                        columnCount: 1,
                                        rowCount: 3 * dimensions + 1 + 1 + 3,
                                    })
                                );
                                for (let i = 0; i < dimensions; i++) {
                                    const conValAddr = new Address(consistancyValuesStartAddr.add({ rowCount: i }));
                                    consistancyCalcRange.getColumn(i).values = [
                                        [
                                            `=SUM(${conValAddr.getValue()}:${conValAddr.add({
                                                columnCount: dimensions - 1,
                                            })})/${weightAddresses[i].getValue()}`,
                                        ],
                                    ];
                                }
                                const lamdaAndOthers = consistancyCalcRange
                                    .getResizedRange(0, -dimensions + 1)
                                    .getOffsetRange(2, -1)
                                    .getResizedRange(2, 1);

                                const lamdaAddr = new Address(
                                    consistancyValuesStartAddr.add({ rowCount: dimensions + 3 })
                                );
                                const crAddr = lamdaAddr.add({ rowCount: 1 });

                                lamdaAndOthers.getColumn(0).values = [["Lamda"], ["CI"], ["CR"]];
                                lamdaAndOthers.getColumn(1).values = [
                                    [
                                        `=AVERAGE(${consistancyValuesStartAddr.add({
                                            rowCount: dimensions + 1,
                                        })}:${consistancyValuesStartAddr.add({
                                            columnCount: dimensions - 1,
                                            rowCount: dimensions + 1,
                                        })})`,
                                    ],
                                    [`=(${lamdaAddr.getValue()}-${dimensions})/(${dimensions}-1)`],
                                    [`=${crAddr}/${cr}`],
                                ];
                            }

                            await context.sync();
                        });
                    }}
                >
                    Generiraj uspoređivanje u parovima
                </Button>
            </Flex>
        </Container>
    );
}

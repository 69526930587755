import { Container, Divider, Text, Flex, Image, Title } from "@mantine/core";
import React from "react";

export default function Upute() {
    return (
        <Container>
            <Title order={2}>Upute</Title>
            <Text fw={700}>Svako rješavanje napravi novi sheet i kopira označene ćelije.</Text>
            <Text fw={700}>Ako ćelije imaju formule kopira ih na novi sheet ali se ne referenciraju dobro. Najbolje koristiti tablice koje imaju zadane vrijednosti, BEZ formuli.</Text>
            <Text fw={700}>Jako je bitno da su tablice dane kak po primjerima.</Text>
            <Text fw={700}>Dimenzije tablici su nebitne. Bitna je struktura npr. da je prvi red uvijek težina kod elektra (prazni red dela, bitno da je označeno).</Text>
            <Divider my="sm" />
            <div>
                <Title order={4}>Rangiranje</Title>
                <Text>Generira tablicu normalizacije po zbroju i tablice za rangiranje.</Text>
                <Text>Transponiraj? transponira tablicu jer bi ocjenjivači trebali biti uvijek na jednoj strani.</Text>
                <Image caption="Očekivana tablica" src={require("./../../../assets/rangiranje.png")} />
                <Divider my="sm" />
            </div>
            <div>
                <Title order={4}>Težine kriterija</Title>
                <Text>Generira odabrane tablice normalizaciji za odabrane metode.</Text>
                <Text fw={700}>
                    SWING treba rucno popuniti dok se zgenerira.
                </Text>
                <Image caption="Očekivana tablica" src={require("./../../../assets/tezine.png")} />
                <Divider my="sm" />
            </div>
            <div>
                <Title order={4}>Uspoređivanje u parovima</Title>
                <Text>Generira tablicu za uspoređivanje u parovima.</Text>
                <Text>Može generirati tablice za konzistentnost.</Text>
                <Text>Ako je CR zadani može se postaviti.</Text>
                <Text fw={700}>CR za 3x3, 4x4, 5x5 je automatski postavljeni z onoga predloška. More se mjenjati.</Text>
                <Divider my="sm" />
            </div>
            <div>
                <Title order={4}>Elektra</Title>
                <Text>Dela sih 10 koraki elektre.</Text>
                <Text>Može početi od čiste tablice odlučivanja, normalizirane matrice odlučivanja ili ponredirane matrice.</Text>
                <Text>Može generirati samo jednu normalizaciju na jenput.</Text>
                <Text>Ako se koristi samo za normalizaciju, ulazna tablica mora biti tablica odlučivanja.</Text>
                <Text fw={700}>Jako bitno da je tablica zadana po primjeru.</Text>
                <Text fw={700}>Očekuje se da su svi kriteriji MAX kriteriji, ako nisu treba ručno napraviti tablicu s MAX kriterijima. MAX kriterij = 1/(MIN kriterij).</Text>
                <Text fw={700}>Apsoultno beskorisno ako se traži da kriteriji moraju biti MIN.</Text>
                <Text fw={700}>Ne generira normalizacije ako ulazna tablica neje tablica odlučivanja.</Text>
                <Image caption="Očekivana tablica" src={require("./../../../assets/elektra.png")} />
                <Divider my="sm" />
            </div>
        </Container>
    );
}

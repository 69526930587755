import { Button, Center, Checkbox, Container, Flex, Group, TextInput, Title } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import {
    fillRangColumn,
    makeROCHeader,
    makeRRHeader,
    makeSWINGHeader,
    NormalizacijaType,
    makeNormalizacijaTable,
} from "../utils/SWING_ROC_RR_utils";
import {
    Address,
    COPYTO_CELL,
    LOAD_ADDRESS,
    makeRangeString,
    RangeToAddress,
    rangeToAddress,
    registerOnSelectionChangeEventHandler,
    removeOnSelectionChangedEventHandler,
    selectionChange,
} from "../utils/utils";

export default function TezineKriterija() {
    const [metodaCheckbox, setMetodaCheckbox] = useState<string[]>(["RR"]);
    const [normalizacijaCheckbox, setNormalizacijaCheckbox] = useState<string[]>(["najmanja"]);
    const [selection, _setSelection] = useState<Excel.Range>(undefined);

    const setSelection = useCallback((range: Excel.Range) => {
        _setSelection((old) => {
            (async () => {
                old.context.trackedObjects.remove(old);
                await old.context.sync();
                console.log("Removing old tracked object");
            })();
            range.context.trackedObjects.add(range);
            return range;
        });
    }, []);

    const selectionChangeHandler = useCallback(async (change: Excel.SelectionChangedEventArgs) => {
        setSelection(await selectionChange(change));
    }, []);

    useEffect(() => {
        const result = registerOnSelectionChangeEventHandler(selectionChangeHandler);
        return () => {
            removeOnSelectionChangedEventHandler(result);
        };
    }, [selectionChange]);

    return (
        <Container>
            <Flex direction="column" gap="sm">
                <Center>
                    <Title order={4}>Težine kriterija</Title>
                </Center>
                <TextInput label={"Odabrane ćelije"} value={selection ? selection.address : ""} disabled />
                <Checkbox.Group value={metodaCheckbox} onChange={setMetodaCheckbox} label={"Metoda:"}>
                    <Group mt="xs">
                        <Checkbox value="SWING" label="SWING" />
                        <Checkbox value="RR" label="RR" />
                        <Checkbox value="ROC" label="ROC" />
                    </Group>
                </Checkbox.Group>
                <Checkbox.Group
                    value={normalizacijaCheckbox}
                    onChange={setNormalizacijaCheckbox}
                    label={"Normalizacija:"}
                >
                    <Group mt="xs">
                        <Checkbox value="najmanja" label="Najmanja v." />
                        <Checkbox value="najveca" label="Najveca v." />
                        <Checkbox value="raspon" label="Raspon v." />
                    </Group>
                </Checkbox.Group>
                <Button
                    color="gray"
                    disabled={metodaCheckbox.length === 0 || normalizacijaCheckbox.length === 0}
                    onClick={() => {
                        Excel.run(selection.context, async (context) => {
                            const newSheet = context.workbook.worksheets.add();
                            newSheet.activate();
                            newSheet.getRange(COPYTO_CELL).copyFrom(selection, undefined, undefined, false);

                            const initialTableColumnCount = selection.columnCount;
                            const initialTableRowCount = selection.rowCount;

                            const kriterijiCount = initialTableColumnCount - 1;
                            const alternativeCount = initialTableRowCount - 2;

                            const MAIN_TABLE_DOWNWARDS_OFFSET = initialTableRowCount + 3;
                            const MAIN_TABLE_RIGHT_OFFSET = initialTableColumnCount + 3;
                            let additionalMethodsCount = 0;

                            const mainTable = newSheet
                                .getRange(COPYTO_CELL)
                                .getResizedRange(initialTableRowCount - 1, initialTableColumnCount - 1);
                            const minMaxRange = mainTable.getRow(0).getResizedRange(0, -1).getOffsetRange(0, 1);
                            const kriterijiRange = minMaxRange.getOffsetRange(1, 0);
                            const mainTableValuesRange = kriterijiRange.getRowsBelow(kriterijiCount);

                            const mainTableValuesStartCell = mainTableValuesRange.getCell(0, 0).load(LOAD_ADDRESS);
                            await context.sync();
                            const mainTableStartCellAddress = new Address(mainTableValuesStartCell.address);

                            // WARN: SWING

                            if (metodaCheckbox.find((v) => v === "SWING")) {
                                const swingTable = newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    )
                                    .getResizedRange(kriterijiCount, 3);
                                newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET - 1,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    ).values = [["SWING"]];

                                makeSWINGHeader(swingTable);
                                fillRangColumn(swingTable, kriterijiCount);

                                swingTable
                                    .getColumn(1)
                                    .getResizedRange(-1, 0)
                                    .getOffsetRange(1, 0)
                                    .copyFrom(kriterijiRange, Excel.RangeCopyType.values, undefined, true);

                                const bodoviColumn = swingTable.getColumn(2);

                                bodoviColumn.getRow(1).values = [["POPUNI RUCNO!!!"]];
                                const bodoviValuesRange = bodoviColumn
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(-1, 0)
                                    .load(LOAD_ADDRESS);
                                await context.sync();
                                const bodoviAddress = rangeToAddress(bodoviValuesRange.address);
                                const sumCell = bodoviColumn.getRowsBelow(1);
                                sumCell.values = [
                                    [
                                        `=SUM(${makeRangeString(
                                            bodoviAddress.from,
                                            bodoviAddress.to ?? bodoviAddress.from
                                        )})`,
                                    ],
                                ];
                                sumCell.load(LOAD_ADDRESS);
                                await context.sync();
                                const sumCellAddress = rangeToAddress(sumCell.address);
                                sumCellAddress.from.freezeRow();

                                const tezineColumn = swingTable
                                    .getColumn(3)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(-1, 0);
                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        tezineColumn.getRow(i).values = [
                                            [
                                                `=${bodoviAddress.from.add({
                                                    rowCount: i,
                                                })}/${sumCellAddress.from.getValue()}`,
                                            ],
                                        ];
                                    }
                                }

                                let rowOffset: number;
                                for (let i = 0; i < normalizacijaCheckbox.length; i++) {
                                    if (i === 0) {
                                        rowOffset = (kriterijiCount + 5) * (i + 1);
                                    } else {
                                        rowOffset += alternativeCount + 5;
                                    }
                                    const pos = swingTable.getOffsetRange(rowOffset, 0);
                                    pos.load(LOAD_ADDRESS);
                                    await context.sync();
                                    const startingPos = rangeToAddress(pos.address);
                                    await makeNormalizacijaTable({
                                        context: context,
                                        mainTable: {
                                            table: mainTable,
                                            rowCount: initialTableRowCount,
                                            columnCount: initialTableColumnCount,
                                            minMaxRange: minMaxRange,
                                            valuesStartCell: mainTableStartCellAddress,
                                        },
                                        startingPosition: startingPos.from.getValue(),
                                        sheet: newSheet,
                                        weightRange: tezineColumn,
                                        kriterijiCount: kriterijiCount,
                                        type:
                                            normalizacijaCheckbox[i] === "najmanja"
                                                ? NormalizacijaType.Najmanja
                                                : normalizacijaCheckbox[i] === "najveca"
                                                    ? NormalizacijaType.Najveca
                                                    : normalizacijaCheckbox[i] === "raspon"
                                                        ? NormalizacijaType.Raspon
                                                        : NormalizacijaType.Najmanja,
                                        alternativeCount: alternativeCount,
                                    });
                                }

                                additionalMethodsCount += 1;
                            }

                            // WARN: RR

                            if (metodaCheckbox.find((v) => v === "RR")) {
                                const rrTable = newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    )
                                    .getResizedRange(kriterijiCount, 3);
                                newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET - 1,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    ).values = [["RR"]];

                                makeRRHeader(rrTable);
                                fillRangColumn(rrTable, kriterijiCount);

                                rrTable
                                    .getColumn(1)
                                    .getResizedRange(-1, 0)
                                    .getOffsetRange(1, 0)
                                    .copyFrom(kriterijiRange, Excel.RangeCopyType.values, undefined, true);

                                const reciprocniRangColumnValues = rrTable
                                    .getColumn(2)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(-1, 0);
                                reciprocniRangColumnValues.load(LOAD_ADDRESS);
                                await context.sync();
                                const reciprocniRangValuesAddress = rangeToAddress(reciprocniRangColumnValues.address);
                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        reciprocniRangColumnValues.getRow(i).values = [
                                            [
                                                `=1/${reciprocniRangValuesAddress.from.add({
                                                    columnCount: -2,
                                                    rowCount: i,
                                                })}`,
                                            ],
                                        ];
                                    }
                                }
                                const sumCell = reciprocniRangColumnValues.getRowsBelow(1);
                                sumCell.values = [
                                    [
                                        `=SUM(${makeRangeString(
                                            reciprocniRangValuesAddress.from,
                                            reciprocniRangValuesAddress.to ?? reciprocniRangValuesAddress.from
                                        )})`,
                                    ],
                                ];
                                sumCell.load(LOAD_ADDRESS);
                                await context.sync();
                                const sumCellAddress = rangeToAddress(sumCell.address);
                                sumCellAddress.from.freezeRow();

                                const tezineColumn = rrTable.getColumn(3).getOffsetRange(1, 0).getResizedRange(-1, 0);
                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        tezineColumn.getRow(i).values = [
                                            [
                                                `=${reciprocniRangValuesAddress.from.add({
                                                    rowCount: i,
                                                })}/${sumCellAddress.from.getValue()}`,
                                            ],
                                        ];
                                    }
                                }

                                let rowOffset: number;
                                for (let i = 0; i < normalizacijaCheckbox.length; i++) {
                                    if (i === 0) {
                                        rowOffset = (kriterijiCount + 5) * (i + 1);
                                    } else {
                                        rowOffset += alternativeCount + 5;
                                    }
                                    const pos = rrTable.getOffsetRange(rowOffset, 0);
                                    pos.load(LOAD_ADDRESS);
                                    await context.sync();
                                    const startingPos = rangeToAddress(pos.address);
                                    await makeNormalizacijaTable({
                                        context: context,
                                        mainTable: {
                                            table: mainTable,
                                            rowCount: initialTableRowCount,
                                            columnCount: initialTableColumnCount,
                                            minMaxRange: minMaxRange,
                                            valuesStartCell: mainTableStartCellAddress,
                                        },
                                        startingPosition: startingPos.from.getValue(),
                                        sheet: newSheet,
                                        weightRange: tezineColumn,
                                        kriterijiCount: kriterijiCount,
                                        type:
                                            normalizacijaCheckbox[i] === "najmanja"
                                                ? NormalizacijaType.Najmanja
                                                : normalizacijaCheckbox[i] === "najveca"
                                                    ? NormalizacijaType.Najveca
                                                    : normalizacijaCheckbox[i] === "raspon"
                                                        ? NormalizacijaType.Raspon
                                                        : NormalizacijaType.Najmanja,
                                        alternativeCount: alternativeCount,
                                    });
                                }
                                additionalMethodsCount += 1;
                            }

                            // WARN: ROC

                            if (metodaCheckbox.find((v) => v === "ROC")) {
                                const rocTable = newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    )
                                    .getResizedRange(kriterijiCount, 4);
                                newSheet
                                    .getRange(COPYTO_CELL)
                                    .getOffsetRange(
                                        MAIN_TABLE_DOWNWARDS_OFFSET - 1,
                                        additionalMethodsCount * MAIN_TABLE_RIGHT_OFFSET
                                    ).values = [["ROC"]];

                                makeROCHeader(rocTable);
                                fillRangColumn(rocTable, kriterijiCount);

                                rocTable
                                    .getColumn(1)
                                    .getResizedRange(-1, 0)
                                    .getOffsetRange(1, 0)
                                    .copyFrom(kriterijiRange, Excel.RangeCopyType.values, undefined, true);

                                const reciprocniRangColumnValues = rocTable
                                    .getColumn(2)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(-1, 0);
                                reciprocniRangColumnValues.load(LOAD_ADDRESS);
                                await context.sync();
                                const reciprocniRangValuesAddress = rangeToAddress(reciprocniRangColumnValues.address);
                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        reciprocniRangColumnValues.getRow(i).values = [
                                            [
                                                `=1/${reciprocniRangValuesAddress.from.add({
                                                    columnCount: -2,
                                                    rowCount: i,
                                                })}`,
                                            ],
                                        ];
                                    }
                                }
                                const sumCell = reciprocniRangColumnValues.getRowsBelow(1);
                                sumCell.values = [
                                    [
                                        `=SUM(${makeRangeString(
                                            reciprocniRangValuesAddress.from,
                                            reciprocniRangValuesAddress.to ?? reciprocniRangValuesAddress.from
                                        )})`,
                                    ],
                                ];
                                sumCell.load(LOAD_ADDRESS);
                                await context.sync();
                                const sumCellAddress = rangeToAddress(sumCell.address);

                                const kumulativColumn = rocTable
                                    .getColumn(3)
                                    .getOffsetRange(1, 0)
                                    .getResizedRange(-1, 0);

                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        if (i === 0) {
                                            kumulativColumn.getRow(i).values = [[`=${sumCellAddress.from.getValue()}`]];
                                        } else {
                                            kumulativColumn.getRow(i).values = [
                                                [
                                                    `=${reciprocniRangValuesAddress.from.add({
                                                        rowCount: i - 1,
                                                        columnCount: 1,
                                                    })}-${reciprocniRangValuesAddress.from.add({
                                                        rowCount: i - 1,
                                                    })}`,
                                                ],
                                            ];
                                        }
                                    }
                                }

                                const tezineColumn = rocTable.getColumn(4).getOffsetRange(1, 0).getResizedRange(-1, 0);
                                {
                                    for (let i = 0; i < kriterijiCount; i++) {
                                        tezineColumn.getRow(i).values = [
                                            [
                                                `=${reciprocniRangValuesAddress.from.add({
                                                    rowCount: i,
                                                    columnCount: 1,
                                                })}/${kriterijiCount}`,
                                            ],
                                        ];
                                    }
                                }

                                let rowOffset: number;
                                for (let i = 0; i < normalizacijaCheckbox.length; i++) {
                                    if (i === 0) {
                                        rowOffset = (kriterijiCount + 5) * (i + 1);
                                    } else {
                                        rowOffset += alternativeCount + 5;
                                    }
                                    const pos = rocTable.getOffsetRange(rowOffset, 0);
                                    pos.load(LOAD_ADDRESS);
                                    await context.sync();
                                    const startingPos = rangeToAddress(pos.address);
                                    await makeNormalizacijaTable({
                                        context: context,
                                        mainTable: {
                                            table: mainTable,
                                            rowCount: initialTableRowCount,
                                            columnCount: initialTableColumnCount,
                                            minMaxRange: minMaxRange,
                                            valuesStartCell: mainTableStartCellAddress,
                                        },
                                        startingPosition: startingPos.from.getValue(),
                                        sheet: newSheet,
                                        weightRange: tezineColumn,
                                        kriterijiCount: kriterijiCount,
                                        type:
                                            normalizacijaCheckbox[i] === "najmanja"
                                                ? NormalizacijaType.Najmanja
                                                : normalizacijaCheckbox[i] === "najveca"
                                                    ? NormalizacijaType.Najveca
                                                    : normalizacijaCheckbox[i] === "raspon"
                                                        ? NormalizacijaType.Raspon
                                                        : NormalizacijaType.Najmanja,
                                        alternativeCount: alternativeCount,
                                    });
                                }
                            }

                            await context.sync();
                        });
                    }}
                >
                    Generiraj težine
                </Button>
            </Flex>
        </Container>
    );
}
